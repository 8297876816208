/* Copyright 2021 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License. */

nav {
  color: #f8fefe;
  background-color: #084564;
  
}

.nav-link {
  background-color: transparent;
}

a {
  color: #f8fefe;
}

.sidebar {
  background-color: #011f2a;
  position: fixed;
  display: grid;
  grid-template-rows: 1fr 0fr 1fr 0fr 0.7fr;
  width: 160px;
  z-index: 2147483644;
  right: 0;
  bottom: 50%;
  height: auto;
  border-top-left-radius: .25rem;
  border: 1px solid #084564;
}

.sidebar-divider {
  background-color: #084564;
  width: 100%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
}

.side-item {
  height: 90px;
}

.sidebar-btn {
  width: 160px;
  height: stretch;
  border-radius: 0px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #0484f1;
  padding: .375rem .75rem;
  font-size: 1rem;
  color: #D8F1F1;
  border-top-left-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.sidebar-btn:hover {
  color: #f8fefe;
  border-radius: 0px;
  border: 1px solid #00d3fb;
  border-top-left-radius: .25rem;
}

@media screen and (max-width: 1700px) {
  .sidebar {
    display: grid;
    grid-template-columns: 1fr 0fr 1fr 0fr 0.7fr;
    width: 100%;
    height: 90px;
    bottom: 0px;
  }
  .sidebar-divider {
    height: 100%;
    width: 1px;
  }
  .sidebar-btn {
    width: 100%;
    height: 90px;
  }
  .sidebar-btn:hover {
    border-radius: 0px;
  }
}
