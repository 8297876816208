/* Copyright 2021 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License. */

@font-face {
  font-family: 'Montserrat Variablefont Wght';
  src: local('Montserrat Italic Variablefont Wght'), url(./fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
  src: local('Montserrat Variablefont Wght'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Opensans Variablefont Wdth Wght';
  src: local('Opensans Variablefont Italic Wdth Wght'), url(./fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf) format('truetype');
  src: local('Opensans Variablefont Wdth Wght'), url(./fonts/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Opensans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #011f2a;
  min-height: 100%;
  padding-bottom: 40px;
}

#root {
  background-color: #011f2a;
  background-image: linear-gradient(#084564, #011f2a);
  background-size: 1400px 1000px;
  background-repeat: repeat-x;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

div {
  color: #f8fefe;  
}

.table {
  background-color: #011f2a;
  --bs-table-hover-color: #f8fefe;
  --bs-table-hover-bg: #084564;
}

thead {
  background-color: #e99500;
}

th {
  color: #f8fefe;
}

tr {
  color: #f8fefe;
  
}

.row {
  max-width: 100%;
}

a {
  color: #f8fefe;
}

.title {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
  font-weight: 500;
}

h4 {
  font-size: 40px;
}

h5 {
  font-size: 20px;
  color: #9cf52d;
}

h6 {
  font-size: 20px;
  color: #e99500;
}

strong {
  color: #e99500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='checkbox'] {
  accent-color: #9cf52d;
}

input[type='text'] {
  accent-color: #9cf52d;
}

.btn-link {
  color: #f8fefe;
}

.btn-link:hover {
  color: #00d3fb;
}

.btn-outline-primary {
  color: #00d3fb;
  border: 1px solid;
}

.btn-outline-primary:hover {
  color: #00d3fb;
  background-color: #084564;
  border: 1px solid #00d3fb;
}

.btn {
  border-radius: 20px;
}

.btn-success {
  border: 2px solid #2fbe00;
}

.btn-success:hover {
  border: 2px solid #9cf52d;
}

.btn-danger {
  border: 2px solid #e55100;
}

.btn-danger:hover {
  border: 2px solid #e99500;
}

.col-md-8 {
  background-color: #011f2a;
}

.card {
  background-color: #011f2a;
  border-color: #084564;
}

.card-title {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
  font-weight: 500;
}

.card-subtitle {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
}

/*@keyframes animated-gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}*/
.ai-log {
  white-space: pre-line;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 180px;
  overflow-y: auto;
  z-index: 999;
}

.ai-log-header {
  padding-bottom: 20px;
  padding-right: 180px;
  border-bottom: 1px solid #084564;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.ai-log-input {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #084564;
  padding-right: 180px;
}

.ai-log-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px; 
  right: 0px;
  width: 100%;
  max-height: 80%;
  padding-right: 1%;
  padding-left: 3%;
  padding-top: 20px;
  padding-bottom: 40px;
  border-radius: .25rem;
  background: linear-gradient(90deg, #011f2a, #084564);
  /*background: linear-gradient(270deg,#011f2a, #084564, #0a97f9, #00d3fb);
  animation: animated-gradient 1s ease infinite;*/
  backdrop-filter: blur(10px);
  background-size: 130% 130%;
  border: 1px solid #084564;
  z-index: 998;
}

.ai-log-container p,
.ai-log-container ul,
.ai-log-container ol,
.ai-log-container li {
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.ai-log p,
.ai-log ul,
.ai-log ol,
.ai-log li {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ai-log-container-minimized {
  position: fixed;
  bottom: 20px;
  right: 20px;
  white-space: pre-line;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 50%;
  background: linear-gradient(90deg, #011f2a, #2fbe00);
  /*background: linear-gradient(270deg,#011f2a, #084564, #0a97f9, #00d3fb);
  animation: animated-gradient 1s ease infinite;*/
  backdrop-filter: blur(10px);
  background-size: 130% 130%;
  border: 2px solid #0a97f9;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  font-weight: bold;
  font-size: 18px;
}

.ai-log-new-message-count {
  position: fixed;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid;
  background-color: #011f2a;
  color: #f8fefe;
  font-size: 16px;
}

.modal-dialog {
  border: 1px solid #00d3fb;
}

.modal-body {
  overflow-y: auto;
}

.modal-content {
  background-color: #084564;
  background-image: linear-gradient(#084564, #011f2a);
}

.btn-outline-secondary {
  color: #D8F1F1;
}

.btn-outline-secondary:hover {
  border: 1px solid #D8F1F1;
  background-color: #084564;
}

.form-text {
  color: #2fbe00;
}

.btn-primary {
background-color: #0a97f9;
border: 1px solid #00d3fb;
}

.btn-primary:hover {
  border: 1px solid #00d3fb;
}

.btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}

.scroll-wrapper {
  overflow: auto;
  background-color: #f8fefe;
  border-radius: .25rem;
  margin-top: 10px;
  margin-bottom: 40px;
}

.scroll-wrapper-dark {
  overflow: auto;
  background-color: #011f2a;
  border-radius: .25rem;
  border: 1px solid #084564;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-top: .18rem;
}

.chart {
  height: 300px;
  width: 70%;
  min-width: 450px;
  margin-left: 0;
  padding-right: 1%;
}

.chart-bar {
  height: 300px;
  width: 28%;
  min-width: 350px;
  padding-left: 1%;
}

.image {
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 376px;
}

.chat-avatar {
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

@media screen and (max-width: 1700px) {

  .ai-log-container {
    bottom: 100px;
  }
  .ai-log-container-minimized {
    bottom: 110px;
    right: 20px;
  }
  .ai-log {
    padding-right: 20px;
  }
  .ai-log-header {
    padding-right: 20px;
  }
  .ai-log-input {
    padding-right: 20px;
    padding-left: 20px;
  }

  .modal-dialog {
    margin: 0;             /* Remove default spacing around the dialog */
    width: 100%;           /* Force full width on small screens */
    max-width: none;       /* Disable any max-width Bootstrap sets */
    height: 80%;          /* Full viewport height */
  }
  .modal-content {
    height: 100%;          /* Let the content fill the dialog’s height */
    border-radius: 0;      /* Remove corner rounding for a native fullscreen look */
  }
  .modal-body {
    overflow-y: auto;      /* Scrollable body if content extends beyond screen */
  }

}